import {Col, Row} from "reactstrap";
import React from "react";
import {Link} from "gatsby";

export const ContactB2B = (props) => (
    <Row>
        <Col md={{size: 6, offset: 3}} className="align-self-center">
            <div className="card mb-4 shadow rounded">
                <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Medoboard</h4>
                </div>
                <div className="card-body">
                    <h1 className="card-title pricing-card-title text-center">Wir suchen Sie
                    </h1>
                    <table className="table table-striped">
                        <tbody>
                        <tr>
                            <td><b>Attraktive Vertriebspartnerschaften</b><br/>
                            Wir sind immer offen für neue Ideen
                            </td>
                        </tr>
                        <tr>
                            <td><b>Markteintritt in Deutschland in Vorbereitung</b><br/>
                            Kontaktieren Sie uns gerne für weitere Details
                            </td>
                        </tr>
                        <tr>
                            <td><b>Innovatives Unternehmen</b><br/>
                            Wir sind agil und haben noch sehr viel vor!
                            </td>
                        </tr>
                        <tr>
                            <td><b>Arbeiten wir gemeinsam</b><br/>
                            und automatisieren wir die Arztpraxis von morgen
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <Link to="/kontakt" className="btn btn-lg btn-block btn-primary">Kontaktieren Sie uns</Link>
                </div>
            </div>
        </Col>
    </Row>
)