import {Col, Row} from "reactstrap";
import React from "react";
import VerschreibungFeaturetteImage from "./VerschreibungFeaturetteImage";

const VerschreibungFeaturette = ({imageTitle}) => (
    <Row className="featurette">
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">{imageTitle}</h2>
                    <p>
                        Medoboard ermöglicht eine intuitive, strukturierte Verschreibung:
                    </p>
                    <ul>
                        <li>Übersichtliche Medikamenteninformationen direkt aus dem Index<br/>
                            Alle medizinisch relevanten Informationen auf einen Blick (und einen Klick).
                        </li>
                        <li>
                            Intuitive Verschreibungsmaske<br/>
                            Verschreiben Sie mit Medoboard mit absoluter Kontrolle:<br/>
                            Wichtige Informationen wie Wirkstoffe und Generika auf einen Blick.
                        </li>
                    </ul>
                </div>
            </div>
        </Col>
        <Col md="5">
            <VerschreibungFeaturetteImage/>
        </Col>
    </Row>
)

export default VerschreibungFeaturette;