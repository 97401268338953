import {Col, Row} from "reactstrap";
import React from "react";
import DruckImage from "./DruckImage";

const DruckFeaturette = ({imageTitle, imageText}) => (
    <Row className="featurette">
        <Col md="5">
            <DruckImage/>
        </Col>
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">Karteidruck</h2>
                    <p>
                        Medoboard bietet eine hochflexible Drucklösung direkt aus der Patientenkartei an:
                    </p>
                    <ul>
                        <li><b>Flexibles Druckmanagement</b><br/>
                            Drucken Sie ihre Rezepte auf jedem Papierformat - und gestalten Sie ihr eigenes Design
                            kinderleicht.
                        </li>
                        <li><b>Automatisierte Druckfelder</b><br/>
                            Medoboard automatisiert den Druck und reduziert damit die notwendige Interaktion auf das
                            absolute Minimum - bei signifikanter Effizienzsteigerung: <br/>
                            Formulare sind mit einem Klick direkt aus der Patientenkartei druckbar.
                        </li>
                    </ul>
                </div>
            </div>
        </Col>
    </Row>
)

export default DruckFeaturette;