import { Col, Row } from 'reactstrap'
import React from 'react'
import PatientenkarteiImage from './PatientenkarteiImage'

const PatientenkarteiFeaturette = ({ imageTitle, imageText }) => (
  <Row className="featurette">
    <Col md="5">
      <PatientenkarteiImage />
    </Col>
    <Col md="7" className="px-5">
      <div className="featurette-content">
        <div>
          <h2 className="mt-4">Patientenkartei</h2>
          <p>
            Medoboard bietet eine übersichtliche Patientenkartei in der Sie
            immer den Blick auf wesentliche Informationen haben:
          </p>
          <ul>
            <li>
              <b>Übersichtliche Visiteninformation</b>
              <br />
              Alle medizinisch relevanten Informationen auf einen Blick (und
              einen Klick). Aber das Wichtigste: Sie entscheiden selbst, welche
              Informationen relevant sind. Medoboard passt sich perfekt an Ihre
              medizinischen Anforderungen an.
            </li>
            <li>
              <b> Visiteneinträge erstellen und verwalten</b>
              <br />
              Mit einem Klick können Sie durch die Patientenhistorie navigieren
              und sehen alle relevanten Parameteränderungen im zeitlichen
              Verlauf
            </li>
            <li>
              <b>Medikamente und Magistrale verschreiben</b>
              <br />
              Verwalten Sie Ihre Magistrale und Medikamentensammlungen mit
              wenigen Klicks und optimieren Sie so die gesamte Verschreibung -
              in Sekunden
            </li>
            <li>
              <b>Erstellen Sie Rezepte und Überweisungen</b>
              <br />
              Medoboard erlaubt den Rezeptdruck in wenigen Klicks. Erstellen Sie
              ihre individuellen Vorlagen und drucken Sie Überweisungen in
              Sekunden
            </li>
          </ul>
        </div>
      </div>
    </Col>
  </Row>
)

export default PatientenkarteiFeaturette
