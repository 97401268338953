import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { withPrefix } from 'gatsby';
import PatientenkarteiFeaturette from '../components/patientenkarteiFeaturette';
import VerschreibungFeaturette from '../components/VerschreibungFeaturette';
import Dmea2019Image from '../components/dmea2019Image';
import { ContactB2B } from '../components/contactB2B';
import DruckFeaturette from '../components/DruckFeaturette';

const Dmea2019 = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
                 googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
                 fbUrl="https://medoboard.com/dmea-2019"
                 fbImage={withPrefix('medoboard-fb.png')}
                 fbTitle="Medoboard - optimiert Ihre Praxis"
                 fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
                 keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
            <Container>
            <Row className="carousel">
                <Col md="12">
                    <Dmea2019Image/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Optimiert Ihre Praxis</h2>
                    <h4 className="text-muted">Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben</h4>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
            <PatientenkarteiFeaturette imageTitle="Patientenkartei" imageText="500x500" heading="Alle Patienten..."
                        subheading="... mit einem Tastendruck" text="Der medizinische Alltag ist geprägt von komplexen Entscheidungen,
                        aufwendigen Diagnosen und zeitraubender Administration."/>
                        </Col>
                        </Row>
                        <hr className="featurette-divider"/>
                        <Row>
                            <Col md="12">
                                <VerschreibungFeaturette imageTitle="Verschreibung" />
                            </Col>
                        </Row>
                        <hr className="featurette-divider"/>
                        <Row>
                            <Col md="12">
                                <DruckFeaturette imageTitle="Druck" />
                            </Col>
                        </Row>
                        <hr className="featurette-divider"/>
            <ContactB2B />
        </Container>
    </Layout>
)

export default Dmea2019;