import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const DruckImage = () => (
    <StaticQuery
        query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Druck.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid}/>}
    />
)
export default DruckImage